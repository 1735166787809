import throttle from 'lodash/throttle'

class GotoTopBtn {
     constructor() {
		this.injectHTML();
		this.btn = document.querySelector(".gotoTopBtn");
		this.scrollThrottle = throttle(this.calculateFade, 200).bind(this);
		this.events();
     }
	
	events() {
		this.btn.addEventListener("click", this.goto);
		window.addEventListener("scroll", this.scrollThrottle);
	}
	

	goto(e) {
		e.preventDefault();
		
		scroll({
			top: 0,
			behavior: "smooth"
		});


		/* $("html, body").animate({
			scrollTop: 0
		}, 800); */
	}
	
	calculateFade() {
		if (window.scrollY > 20) {
			$(this.btn).fadeIn();
		} else {
			$(this.btn).fadeOut();
		}
	}
	
	injectHTML() {
		document.body.insertAdjacentHTML('beforeend', `<a href="#" class="gotoTopBtn"></a>`);
	}

}

export default GotoTopBtn


///*<a href="#" class="gotoTopBtn gotoTopBtn__cd-top text-replace gotoTopBtn__js-cd-top gotoTopBtn__cd-top--is-visible gotoTopBtn__cd-top--fade-out" data-abc="true"></a>*/








