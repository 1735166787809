class FreePlaceTable {
     constructor(tableData, saisons) {
          this.tableData = tableData;
          this.saisons = saisons;

          // console.log(tableData, saisons);
     }

     render() {
          if (window.DEV_MODE) {
               return this.renderDev()
          } else {
               return this.renderProd()
          }
     }


     renderProd() {
          const STATUS_UNKNOWN = "0";
          const STATUS_MAP = {
               "-1": '<i class="fas fa-square edumap__indic--busy"></i>',
               "0": '<i class="fas fa-square edumap__indic--unknown"></i>',
               "1": '<i class="fas fa-square edumap__indic--free"></i>'
          };


          let tableEntries = Object.entries(this.tableData);
          if (!tableEntries.length) return "";


          let allRows = ""


          let row = ""
          let cell = ""

          cell += `<div class="col-md-6"><h5><strong>Ausbildungsplätze</strong></h5></div>`;
          row += cell

          let saisonCell = ""
          this.saisons.forEach(saison => {
               saisonCell += `<div class="col text-center" title="${saison.label}">${saison.shortLabel}</div>`;
          });
          saisonCell = `<div class="col-md-6 d-none d-md-block"><div class="row no-gutters">${saisonCell}</div></div>`
          row += saisonCell
          row = `<div class="row no-gutters mb-2">${row}</div>`

          allRows += row



          tableEntries.forEach((el, idx) => {
               let row = ""

               // Left
               let cellLeft = `<div class="col-md-6 mb-2"><div class="edumap__beruf-label">${el[1]['display_name']}</div></div>`;
               row += cellLeft

               // right
               let rightCellContent = ``;
               this.saisons.forEach(saison => {
                    const statusContent = el[1][saison.year] !== undefined ? STATUS_MAP[el[1][saison.year]] : STATUS_MAP[STATUS_UNKNOWN]

                    rightCellContent += `
                    <div class="col text-center">
                         <div class="d-md-none" title="${saison.label}">${saison.shortLabel}</div >
                         <div>${statusContent}</div>
                    </div>`
               });
               let cellRight = `<div class="col-md-6"><div class="row no-gutters">${rightCellContent}</div></div>`;
               row += cellRight
               row = `<div class="row no-gutters mb-4">${row}</div>`

               allRows += row
          });


          return `
               <!-- <h5><strong>Ausbildungsplätze</strong></h5> -->
               <div class="pb-3">
                    ${allRows}
               </div>
          `;

          // <div class="table-responsive-sm table-sm">
          //      <table class="table table-borderless">
          //           <tbody>
          //                <tr>${headers}</tr>
          //                ${rows}
          //           </tbody>
          //      </table>
          // </div>
     }


     renderDev() {
          const STATUS_UNKNOWN = "0";
          const STATUS_MAP = {
               "-1": '<i class="fas fa-square edumap__indic--busy"></i>',
               "0": '<i class="fas fa-square edumap__indic--unknown"></i>',
               "1": '<i class="fas fa-square edumap__indic--free"></i>'
          };


          let tableEntries = Object.entries(this.tableData);
          if (!tableEntries.length) return "";

          
          let allRows = ""


          let row = ""
          let cell = ""

          cell += `<div class="col-md-6"><h5><strong>Ausbildungsplätze</strong></h5></div>`;
          row += cell

          let saisonCell = ""
          this.saisons.forEach(saison => {
               saisonCell += `<div class="col text-center" title="${saison.label}">${saison.shortLabel}</div>`;
          });
          saisonCell = `<div class="col-md-6 d-none d-md-block"><div class="row no-gutters">${saisonCell}</div></div>`
          row += saisonCell
          row = `<div class="row no-gutters mb-2">${row}</div>`

          allRows += row



          tableEntries.forEach((el, idx) => {
               let row = ""

               // Left
               let cellLeft = `<div class="col-md-6 mb-2"><div class="edumap__beruf-label">${el[1]['display_name']}</div></div>`;
               row += cellLeft

               // right
               let rightCellContent = ``;
               this.saisons.forEach(saison => {
                    const statusContent = el[1][saison.year] !== undefined ? STATUS_MAP[el[1][saison.year]] : STATUS_MAP[STATUS_UNKNOWN]

                    rightCellContent += `
                    <div class="col text-center">
                         <div class="d-md-none" title="${saison.label}">${saison.shortLabel}</div >
                         <div>${statusContent}</div>
                    </div>`
               });
               let cellRight = `<div class="col-md-6"><div class="row no-gutters">${rightCellContent}</div></div>`;
               row += cellRight
               row = `<div class="row no-gutters mb-4">${row}</div>`

               allRows += row
          });


          return `
               <!-- <h5><strong>Ausbildungsplätze</strong></h5> -->
               <div class="pb-3">
                    ${allRows}
               </div>
          `;

          // <div class="table-responsive-sm table-sm">
          //      <table class="table table-borderless">
          //           <tbody>
          //                <tr>${headers}</tr>
          //                ${rows}
          //           </tbody>
          //      </table>
          // </div>
     }
     
}
window.FreePlaceTable = FreePlaceTable

export default FreePlaceTable

/* module.exports.FreePlaceTable = FreePlaceTable */
