class MatomoTracking {
     constructor(siteId) {
          this.siteId = siteId;
          this.start();
     }

     start() {
          /* Matomo */
          var _paq = window._paq = window._paq = [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
		_paq.push(['disableCookies']);
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function(siteId) {
               var u="https://matomo.lwk-niedersachsen.de/";
               _paq.push(['setTrackerUrl', u+'matomo.php']);
               _paq.push(['setSiteId', siteId]);
               var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
               g.type='text/javascript';
               g.async=true; g.src=u+'matomo.js';
               s.parentNode.insertBefore(g,s);
          })(this.siteId);
          /* End Matomo Code */
     }
}

export default MatomoTracking