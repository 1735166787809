import { DeepChat } from "deep-chat"

class ChatBot {

     constructor() {
          this.$chatOpenBtn = $(".js-chatbot-open-btn")
          this.$chatEl = $("#js-chat-element")

          this.initChat()
          this.events()
     }

     events() {

          this.$chatOpenBtn.on("click", this.chatOpen.bind(this))
     }


     initChat() {
          const elementRef = this.$chatEl.get(0)

          if (!elementRef) return

          // Setting value via a property (easiest way)
          // elementRef.initialMessages = [
          //     { role: "user", text: "Hey, how are you today?" },
          //     { role: "ai", text: "I am doing very well!" },
          // ];

          elementRef.setAttribute(
               "introMessage",
               JSON.stringify({
                    text: "Hi 👋, wie kann ich dir helfen?",
               })
          );
     }


     chatOpen() {
          // alert("open")
     }


}

export default ChatBot